import React, { useState, useEffect, useRef  } from 'react';
import useCartStore from '../api/cart';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';

const Thankyou = () =>{
    const location = useLocation()
    const params = new URLSearchParams(location.search);
    
    const today = new Date();
    const formattedDate = `${today.toLocaleString('default', { month: 'long' })} ${today.getDate()}, ${today.getFullYear()}`;
    const { removeFromCart, cartItems } = useCartStore();
    const fetchOrderData = async () => {
        const id = params.get('id')
        const response = await axios.get(`${process.env.REACT_APP_API_URL}order/getonebyid/${id}`)
        return response.data[0];
    }

    const {
        data , isLoading
    } = useQuery({
        queryKey: ["fetchOrderData"],
        queryFn: fetchOrderData,
    })

    const navigate = useNavigate()

    useEffect(() => {
        if(cartItems && cartItems.length != 0){
            removeFromCart(cartItems[0]?.id)
            localStorage.removeItem("product-store")
            localStorage.setItem("thankyoupage", "yes")
        }
        if(localStorage.getItem("thankyoupage")  != "yes"){
            localStorage.removeItem("product-store")
            toast.error("You are not authorized. Go away!")
            navigate("/");
        }
    },[])
    
    return(
        <section className='thankyou-section relative' >
            {!isLoading &&
                <div className="container padding-x-all">
                <div className="thankyou-box sec-com-padding">
                    <h1>
                        Thank you for your order, {data?.customer_details?.name}! <br />
                        Your order has been placed.
                    </h1>
                    <p className='thankyou-para' >Check your email for your order confirmation.</p>
                    <button className='section-main-btn'>Order Reference: 
                        {data?.custom_order_id}</button>
                    <p className='thankyou-para'>Order Date: {new Date(data?.createdAt).toLocaleDateString("en-US", { timeZone: "America/Los_Angeles" })}</p>
                    <div className="collection-name-box flex-box">
                        <div className="collection-date-box">
                            <h5>Pickup</h5>
                            <p className='thankyou-para' >{new Date(data?.createdAt).toLocaleDateString("en-US", { timeZone: "America/Los_Angeles" })}</p>
                        </div>
                        <div className="name-address-box">
                            <h5>{data?.customer_details?.name}</h5>
                        </div>
                    </div>
                    <h2>
                    How to prepare your item for pickup:
                    </h2>
                    <ul className='prepare-listing' >
                        <li>
                            <h6><span>1</span>Wash and clean your item</h6>
                        </li>
                        <li>
                        <h6><span>2</span>Pin your item or send along an item to match unless you included measurements in your order</h6>
                        </li>
                        <li>
                        <h6><span>3</span>Your item is ready to give to one of our drivers</h6>
                        </li>
                    </ul>
                    <p className='problem-text' >
                    If you have any problems with your order, we are here to help. <br />
                        Check our <a href="https://www.thefxry.com/faq">FAQs</a> or <a href="https://www.thefxry.com/contact-us">Contact us.</a>
                    </p>
                </div>
            </div>
            }
        </section>
    )
}

export default Thankyou